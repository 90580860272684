import React from 'react';
import Layout from '../components/layout/Layout';
import { graphql } from 'gatsby';
import { Trans } from 'gatsby-plugin-react-i18next';

const NotFound = ({
  location,
}) => (
  <Layout location={location}>
    <div class="flex items-center justify-center my-40">
      <h1 class="text-2xl">404</h1>
      <div class="w-px h-6 m-2 bg-black"/>
      <p><Trans>La page est introuvable.</Trans></p>
    </div>
  </Layout>
);

export default NotFound;

export const query = graphql`
  query {
    locales: allLocale{
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`